import {
  Button,
  Col,
  FieldInput,
  Form,
  Label,
  Message,
  Modal,
  Row,
  Select,
  Spin,
  Text,
  usePrevTheme
} from '@prev/ui-kit'
import React, { useState } from 'react'
import OnboardingSvg from '../assets/OnboardingSvg.svg'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { changeAccountInfo, getSlug, updateOnboarding } from '../services'
import { estadosOab, slugToUrl } from '../utils'

interface OnboardingModalProps {
  isOpen: boolean
  onClose: () => void
  onboardingUser: any
}

export const profissoesUsuario = [
  {
    id: 'ADVOGADO',
    desc: 'Advogado'
  },
  {
    id: 'BACHAREL_EM_DIREITO',
    desc: 'Bacharel em Direito'
  },
  {
    id: 'ACADEMICO_DO_DIREITO',
    desc: 'Acadêmico de Direito'
  },
  {
    id: 'ESTAGIARIO',
    desc: 'Estagiário'
  },
  {
    id: 'SERVIDOR_PUBLICO',
    desc: 'Servidor Público'
  },
  {
    id: 'CONTADOR',
    desc: 'Contador'
  },
  {
    id: 'APOSENTADO_OU_SEGURADO',
    desc: 'Aposentado ou segurado'
  },
  {
    id: 'OUTRO',
    desc: 'Outro'
  }
]

interface HandleOab {
  ufOab: string
  numeroOabClean: string
}

interface OfficeInfo {
  email: string
  telefone: string
  nome: string
}

export const OnboardingModal = (props: OnboardingModalProps): JSX.Element => {
  const { isOpen, onClose, onboardingUser } = props
  const [step, setStep] = useState(0)
  const [selectedProfissao, setProfissao] = useState('')
  const { theme } = usePrevTheme()
  const [oab, setOAB] = useState<string>()
  const [formOab] = Form.useForm<HandleOab>()
  const [formOffice] = Form.useForm<OfficeInfo>()
  const [officeInfo, setOfficeInfo] = useState<OfficeInfo>()
  const [slug, setSlug] = useState<string>()
  const [slugForm] = Form.useForm<{ slug: string }>()
  const [checkingSlug, setCheckingSlug] = useState(false)
  const [availability, setAvalible] = useState<boolean | null>(false)
  const [loading, setLoading] = useState(false)

  const handleOab = (values: HandleOab): void => {
    const oabFinal = `OAB/${values.ufOab} ${values.numeroOabClean}`
    setOAB(oabFinal)
    setStep(2)
  }

  const handleOfficeInfo = (values: OfficeInfo): void => {
    setOfficeInfo(values)
    setStep(3)
  }

  const handleFinish = async (values: { slug: string }): Promise<void> => {
    try {
      setLoading(true)
      if (!/^[a-z0-9]*$/.test(values.slug)) return
      if (!checkLength(values.slug)) return
      if (values.slug?.includes(' ') || /[^\w]/.test(values.slug || '')) return
      if (!availability) return
      const oldUsuario = {
        ...onboardingUser.usuario
      }

      const payloadsUser = {
        ...oldUsuario,
        numeroDaOAB: oab ?? undefined,
        profissao: selectedProfissao
      }

      if (!oab) delete payloadsUser.numeroDaOAB
      await changeAccountInfo(payloadsUser)
      await updateOnboarding({
        nome: officeInfo?.nome,
        email: officeInfo?.email,
        telefone: officeInfo?.telefone,
        slug
      })
      setLoading(false)
      localStorage.removeItem('access_token_prev')
      slugToUrl(slug ?? "")
    } catch (err) {
      setLoading(false)
      Message.error(err.message || 'Algo deu errado ao atualizar as informações')
    }
  }

  const validateInput = async (value): Promise<void> => {
    try {
      setSlug(value)
      setCheckingSlug(true)
      if (
        !/^[a-z0-9]*$/.test(value) ||
        !checkLength(value) ||
        value?.includes(' ') ||
        /[^\w]/.test(value || '')
      ) {
        setAvalible(null)
        setCheckingSlug(false)
        return
      }
      const response = await getSlug(value)
      if (response) {
        setAvalible(false)
      }
      setCheckingSlug(false)
    } catch (err: unknown) {
      console.log("err validate input:", err)
      setCheckingSlug(false)
      setAvalible(true)
    }
  }

  const addDotAfterHundred = (oabNumber: string): string => {
    if (oabNumber?.length >= 4) {
      oabNumber = oabNumber.slice(0, -3) + '.' + oabNumber.slice(-3)
    }
    return oabNumber
  }


  const checkLength = (value): boolean => value?.length <= 20

  const debouncedCheck = debounce(validateInput, 500)

  return (
    <Modal
      data-testid='modal-onboarding'
      open={isOpen}
      style={{ height: '700px' }}
      onClose={onClose}
      closable={false}
      footer={() => {
        if (step === 0) {
          return (
            <Button
              key="avancarOnboarding"
              type="primary"
              disabled={!selectedProfissao}
              onClick={() => {
                if (selectedProfissao === 'ADVOGADO') {
                  setStep(1)
                } else {
                  setStep(2)
                }
              }}
            >
              Avançar
            </Button>
          )
        }
        if (step === 1) {
          return [
            <Button
              key="voltarOnboarding"
              type="subtle"
              disabled={!selectedProfissao}
              onClick={() => {
                formOab.resetFields()
                setStep(0)
              }}
            >
              Voltar
            </Button>,
            <Button
              key="avancarOnboarding"
              type="primary"
              disabled={!selectedProfissao}
              onClick={formOab.submit}
            >
              Avançar
            </Button>
          ]
        }
        if (step === 2) {
          return [
            <Button
              key="voltarOnboarding"
              type="subtle"
              disabled={!selectedProfissao}
              onClick={() => {
                formOab.resetFields()
                setStep(0)
              }}
            >
              Voltar
            </Button>,
            <Button
              key="avancarOnboarding"
              type="primary"
              disabled={!selectedProfissao}
              onClick={formOffice.submit}
            >
              Avançar
            </Button>
          ]
        }
        if (step === 3) {
          return [
            <Button
              key="voltarOnboarding"
              type="subtle"
              disabled={!selectedProfissao}
              loading={loading}
              onClick={() => {
                formOab.resetFields()
                setStep(2)
              }}
            >
              Voltar
            </Button>,
            <Button
              key="avancarOnboarding"
              type="primary"
              disabled={!availability || checkingSlug || !slug}
              onClick={slugForm.submit}
              loading={loading}
            >
              Finalizar
            </Button>
          ]
        }
      }}
    >
      <Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
        <img src={OnboardingSvg} />
      </Row>
      {step === 0 && (
        <>
          <Text style={{ marginBottom: '15px' }} emphasis size="h6">
            Boas-vindas ao Prev! Estamos quase finalizando.
          </Text>
          <Text style={{ marginBottom: '15px' }} emphasis>
            Por favor, selecione a sua profissão:
          </Text>
          <Text
            style={{ marginBottom: '15px' }}
            color={theme.color.text.subtlest}
          >
            Simplifique sua gestão previdenciária. Configure seu escritório
            virtual e facilite suas demandas
          </Text>
          <Row style={{ gap: '20px' }}>
            {profissoesUsuario.map((profissao) => (
              <Button
                key={profissao.id}
                style={{
                  backgroundColor:
                    profissao.id === selectedProfissao ? '#FAC1A8' : 'inherit',
                  border: `1px solid ${theme.color.border.disabled}`
                }}
                type={'subtle'}
                onClick={() => {
                  setProfissao(profissao.id)
                }}
              >
                {profissao.desc}
              </Button>
            ))}
          </Row>
        </>
      )}
      {step === 1 && (
        <>
          <Text style={{ marginBottom: '15px' }} emphasis size="h6">
            Um pouco mais sobre sua profissão
          </Text>
          <Text style={{ marginBottom: '15px' }} emphasis>
            Preencha os campos solicitados:
          </Text>
          <Text
            style={{ marginBottom: '15px' }}
            color={theme.color.text.subtlest}
          >
            Precisamos saber sua OAB e onde atua, isso tornará sua jornada mais
            completa aqui conosco.
          </Text>
          <Form data-testid='form-onboard' form={formOab} onFinish={handleOab}>
            <Row style={{ gap: '20px' }}>
              <Col>
                <Label>
                  UF OAB
                  <Form.Item
                    name="ufOab"
                    style={{ width: '100px' }}
                    rules={[
                      { required: true, message: 'O estado da OAB é obrigatório' }
                    ]}
                  >
                    <Select style={{ height: "40px" }} data-testid='select-estado-oab' options={estadosOab} />
                  </Form.Item>
                </Label>
              </Col>
              <Col>
                <Label>
                  Número OAB
                  <Form.Item
                    rules={[
                      {
                        message: 'Por favor, informe um número válido.',
                        required: true,
                        max: 7
                      }
                    ]}
                    name="numeroOabClean"
                  >
                    <FieldInput
                      data-testid='numero-oab'
                      onChange={(event) => {
                        formOab.setFieldValue(
                          'numeroOabClean',
                          addDotAfterHundred(event.target.value?.replace(/\D/g, ''))
                        )
                      }}
                      style={{ width: '100%' }}
                      maxLength={7}
                    />
                  </Form.Item>
                </Label>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {step === 2 && (
        <>
          <Text style={{ marginBottom: '15px' }} emphasis size="h6">
            Vamos montar seu escritório?
          </Text>
          <Text
            style={{ marginBottom: '15px' }}
            color={theme.color.text.subtlest}
          >
            Configure seu escritório virtual e facilite suas demandas. Segurados
            confiam em um perfil previdenciário completo! Adicione suas
            informações:
          </Text>
          <Form data-testid='form-dados-escritorio' form={formOffice} onFinish={handleOfficeInfo}>
            <Col>
              <Label>
                Nome do escritório (você pode alterar este nome depois)
                <Form.Item
                  name="nome"
                  rules={[
                    {
                      required: true,
                      message: 'O nome do escritório é obrigatório'
                    }
                  ]}
                >
                  <FieldInput
                    data-testid='input-nome-escritorio'
                    placeholder="Digite um nome para o seu escritório"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col>
              <Label>
                E-mail do escritório
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'E-mail do escritório é obrigatório'
                    },
                    { type: 'email', message: 'E-mail inválido' }
                  ]}
                >
                  <FieldInput
                    data-testid='input-email'
                    placeholder="Ex: escritorio@gmail.com"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col>
              <Label>
                Telefone do escritório
                <FieldInput.Phone
                  data-testid='input-phone'
                  name='telefone'
                  rules={[
                    {
                      required: true,
                      message: 'Telefone do escritório é obrigatório'
                    },
                    {
                      message: 'Número inválido',
                      min: 10
                    }
                  ]}
                  style={{ width: '100%' }}
                  placeholder="(00) 00000-0000"
                />
              </Label>
            </Col>
          </Form>
        </>
      )}
      {step === 3 && (
        <>
          <Text style={{ marginBottom: '15px' }} emphasis size="h6">
            Última etapa!
          </Text>
          <Text style={{ marginBottom: '15px' }} emphasis>
            Vamos criar um link para seu escritório:
          </Text>
          <Text
            style={{ marginBottom: '15px' }}
            color={theme.color.text.subtlest}
          >
            Este endereço será único e exclusivo do seu escritório. É por ele
            que você e sua equipe irão realizar o login. Dica: Escolha um nome
            acessível para que seja facilmente lembrado
          </Text>
          <Form data-testid='form-link-escritorio' form={slugForm} onFinish={handleFinish}>
            <Label>
              Link do escritório
              <Form.Item
                style={{ marginBottom: '5px' }}
                name="slug"
                hasFeedback={false}
                help={null}
                validateStatus=""
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira um nome para o link do escritório'
                  }
                ]}
              >
                <FieldInput
                  data-testid='input-nome-escritorio'
                  placeholder="Ex: nomedoescritório"
                  value={slug}
                  onChange={(e) => {
                    void debouncedCheck(e.target.value)
                  }}
                />
              </Form.Item>
            </Label>
            {checkingSlug && (
              <Text
                style={{
                  margin: '5px 0',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Spin data-testid='spin-checando-slug' />
              </Text>
            )}
            {slug && !checkingSlug && availability !== null && (
              <Text
                style={{
                  color: availability ? 'green' : 'red',
                  margin: '5px 0'
                }}
              >
                Endereço {availability ? 'disponível' : 'indisponível'} para uso
              </Text>
            )}
            <div style={{ marginBottom: '20px' }}>
              <Text
                style={{
                  color: slug && slug?.length <= 20 ? 'green' : 'red',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                {slug && slug?.length <= 20 ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )}
                Máximo de 20 caracteres
              </Text>
              <Text
                style={{
                  color: /^[a-z0-9]*$/.test(slug ?? '') ? 'green' : 'red',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                {/^[a-z0-9]*$/.test(slug ?? '') ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )}
                Apenas letras minúsculas e números
              </Text>
              <Text
                style={{
                  color:
                    !slug?.includes(' ') && !/[^\w]/.test(slug ?? '')
                      ? 'green'
                      : 'red',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                {!slug?.includes(' ') && !/[^\w]/.test(slug ?? '') ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )}
                Sem espaços, caracteres especiais, hifens, underline, etc...
              </Text>
            </div>
          </Form>
        </>
      )}
    </Modal>
  )
}
