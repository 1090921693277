// template-app/src/Root.js
import { LocalLoading, PrevPlatformProvider } from '@prev/ui-kit'
import { PrevLayout } from './pages/LayoutPrev'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from 'react-router-dom'
import ChildAppPlaceholder from './pages/ChildAppPlaceholder'
import React, { Suspense, useEffect, useState } from 'react'
import { activateInterceptors, getToken } from '@prev-front/shared-api'
import { LoginModal } from './components/LoginModal'
import UserProvider from './contexts/UserContext'
import SearchProvider from './contexts/SearchContext'
import { JwtCheck } from "@prev-front/shared-api"

export default function Root() {
  const token = getToken()
  const [relogin, setRelogin] = useState(false)
  const [checkedJwt, setCheckedJwt] = useState<boolean | undefined>()

  const handleJwt = async () => {
    const value = await JwtCheck()
    setCheckedJwt(value)
  }

  useEffect(() => {
    void handleJwt()
  }, [])

  const handleRelogin = (state: boolean) => {
    setRelogin(state)
  }

  if (!token && !relogin) {
    window.location.href = '/login'
    return <></>
  }


  if (!checkedJwt) return <LocalLoading display={true} />

  activateInterceptors({ setRelogin: handleRelogin })

  return (
    <PrevPlatformProvider>
      <Router>
        <Suspense>
          <UserProvider>
            <SearchProvider>
              <PrevLayout>
                <LoginModal open={relogin} openLoginModal={setRelogin} />
                {
                  !relogin && (
                    <Routes >
                      <Route path="/" element={<Layout />}>
                        {/* mf-dashboard */}
                        <Route path="/" element={<ChildAppPlaceholder appName="@prev-front/mf-core" />} />
                        <Route path="/configuracoes" element={<ChildAppPlaceholder appName="@prev-front/mf-core" />} />
                        <Route path="/configuracoes/conta" element={<ChildAppPlaceholder appName="@prev-front/mf-core" />} />
                        <Route path="/configuracoes/financeiro" element={<ChildAppPlaceholder appName="@prev-front/mf-core" />} />
                        <Route path="/cursos" element={<ChildAppPlaceholder appName="@prev-front/mf-core" />} />
                        {/* mf-clientes */}
                        <Route path="/clientes" element={<ChildAppPlaceholder appName="@prev-front/mf-core" />} />
                        <Route path="/clientes/:cpf" element={<ChildAppPlaceholder appName="@prev-front/mf-core" />} />
                        {/* mf-peticoes */}
                        <Route path="/jurisprudencia" element={<ChildAppPlaceholder appName="@prev-front/mf-documentos" />} />
                        <Route path="/peticoes" element={<ChildAppPlaceholder appName="@prev-front/mf-documentos" />} />
                        <Route path="/casos" element={<ChildAppPlaceholder appName="@prev-front/mf-documentos" />} />
                        <Route path="/laudos" element={<ChildAppPlaceholder appName="@prev-front/mf-documentos" />} />
                        {/* mf-calculos */}
                      </Route>
                    </Routes>
                  )
                }
              </PrevLayout>
            </SearchProvider>
          </UserProvider>
        </Suspense>
      </Router>
    </PrevPlatformProvider>
  )
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  )
}
