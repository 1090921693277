/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { SearchContext } from '../contexts/SearchContext';
import { usePrevTheme } from '@prev/ui-kit';
import { UserContext } from '../contexts/UserContext';

function ChildAppPlaceholder({ appName }) {
  const prevPlataformProviderProps = usePrevTheme()
  const searchProps = useContext(SearchContext);
  const userContext = useContext(UserContext)
  const [lastApp, setLastApp] = useState<any>()
  const [lastName, setLastName] = useState<any>()

  useEffect(() => {
    if (!prevPlataformProviderProps || !userContext.consumoModulos || !userContext.modules || !userContext.userInfo || !userContext.userProfile) return
    loadChildApp()
  }, [appName, searchProps.searchQuery, prevPlataformProviderProps, userContext.consumoModulos, userContext.modules, userContext.userInfo, userContext.userProfile])


  const loadChildApp = async () => {
    try {
      if (!appName) return
      if (appName === lastName) {
        lastApp.update({
          context: { themeProps: { ...prevPlataformProviderProps }, searchProps: { ...searchProps }, userProps: { ...userContext } },
        })
        return
      }
      if (lastApp && lastName) {
        await lastApp?.unmount({
          domElement: document.getElementById(lastName),
          context: { themeProps: { ...prevPlataformProviderProps }, searchProps: { ...searchProps }, userProps: { ...userContext } },
        });
      }
      const newApp = await System.import(appName);
      await newApp.mount({
        domElement: document.getElementById(appName),
        context: { themeProps: { ...prevPlataformProviderProps }, searchProps: { ...searchProps }, userProps: { ...userContext } },
      });
      setLastApp(newApp)
      setLastName(appName)
    } catch (err) {
      console.error("Error loading child app", err);
    }
  };

  if (!prevPlataformProviderProps || !userContext.consumoModulos || !userContext.modules || !userContext.userInfo || !userContext.userProfile) {
    return (
      <div />
    )
  }

  return (
    <div id={appName} />
  );
}

export default ChildAppPlaceholder;