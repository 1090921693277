/* eslint-disable react/prop-types */
import React, { act, createContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { SearchContextInterface } from './SearchContextTypes'

export const SearchContext = createContext<SearchContextInterface>({} as SearchContextInterface)

interface SearchProvider {
    children: React.ReactNode
}

const SearchProvider = ({ children }: SearchProvider) => {
    const [query, setQuery] = useState("")
    const [searchQuery, setSearchQuery] = useState("")
    const [actualPath, setActualPath] = useState('/')

    const navigate = useNavigate()

    const handleFindBy = (path: string, search?: string): void => {
        if (!path.includes(actualPath)) {
            if (path === 'peticoes') {
                setActualPath('/peticoes')
            } else if (path === 'clientes') {
                setActualPath('/clientes')
            } else if (path === 'jurisprudencia') {
                setActualPath('/jurisprudencia')
            }
            if (navigate) {
                setSearchQuery(search ? search : query)
                saveSearch(search ? search : query, path)
                navigate(`/${path}`)
            }
        } else {
            setSearchQuery(search ? search : query)
            saveSearch(search ? search : query, path)
        }
    }

    const saveSearch = (search: string, where: string): void => {
        const searchHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]') || Array(5)
        const uniqueHistory = searchHistory?.filter(
            (item: { search: string, where: string }) => {
                return !(
                    item.search.toLowerCase() === search.toLowerCase() &&
                    item.where === where
                )
            }
        )
        uniqueHistory?.unshift({ search, where })
        if (uniqueHistory?.length > 5) {
            uniqueHistory?.pop()
        }
        localStorage.setItem('searchHistory', JSON.stringify(uniqueHistory))
    }

    return (
        <SearchContext.Provider value={{
            query,
            setQuery,
            setSearchQuery,
            actualPath,
            setActualPath,
            handleFindBy,
            searchQuery,
        }}>
            {children}
        </SearchContext.Provider>
    )
}

export default SearchProvider