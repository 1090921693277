import { Modal, Form, Button, Message, Text, FieldInput, Label } from "@prev/ui-kit";
import React from "react";
import styled from "styled-components";
import { getSlugFromUrl } from "../utils";
import { HistoryOutlined } from "@ant-design/icons";
import { login } from "../services";
import { ApiError, getCookie, setCrossSubdomainCookie } from "@prev-front/shared-api";
import { v4 as uuidv4 } from 'uuid'

const LabelText = styled.div`
  font-size: 126x;
  color: rgba(0, 0, 0, 0.4);
`;

const emailOuCPFRegex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|(\d{3}[-.]?\d{3}[-.]?\d{3}[-.]?\d{2}))$/

const errorMsg = (msg) => {
  Message.error(msg);
};

interface LoginModalInterface {
    open: boolean
    openLoginModal: any
}

export const LoginModal = (props: LoginModalInterface) => {
    const { open, openLoginModal } = props

    const onSubmit = async (values) => {
      try {
          const slug = getSlugFromUrl()
          const formatedData = {
            grant_type: slug ? "slug" : "password",
            slug: slug ?? '',
            username: values.username,
            password: values.password
          }
          if (formatedData && 'slug' in formatedData && !formatedData.slug) {
            /* @ts-expect-error asdasd */
            delete formatedData.slug
          }
          const response = await login(formatedData)
          localStorage.setItem('access_token_prev', response.access_token)
          const cookie = getCookie('prev_session')
          if (!cookie) setCrossSubdomainCookie('prev_session', uuidv4(), 365)
          openLoginModal(false)
        } catch (err) {
        if (err instanceof ApiError) void Message.error(err.message)
        if (!(err instanceof ApiError)) void Message.error('Algo deu errado, tente novamente mais tarde')
        }
    }

    const onClose = () => {
        openLoginModal(false);
        window.localStorage.removeItem("access_token_prev");
        window.localStorage.removeItem("refresh_token_prev");
        window.location.reload();
    }

    return (
      <Modal
        data-testid="modal-relogin"
        open={open}
        footer={null}
        bodyStyle={{ padding: "0px", zIndex: 999999999 }}
        width="476px"
        maskClosable={false}
        maskStyle={{
          backdropFilter: 'blur(10px)', // Apply blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)' // Optional: darker background with opacity
        }}
        onCancel={onClose}
        style={{
          width: "476px",
          padding: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          <HistoryOutlined style={{ fontSize: "50px", color: "#FAAD14" }} />
          <Text
            size="h6"
            style={{ fontWeight: 700, fontSize: "20px", textAlign: "center" }}
          >
            Sua sessão expirou
          </Text>
          <Text style={{ textAlign: "center" }}>
            Por favor, insira seus dados para continuar usando a sua conta
          </Text>
          <Form data-testid="relogin-form" style={{ width: "100%" }} onFinish={onSubmit}>
            <Label>
              Email ou CPF
            <Form.Item
                name="username"
                style={{ margin: '25px 0' }}
                validateDebounce={500}
                required={false}
                rules={[
                    {
                    required: true,
                    message: 'Campo obrigatório'
                    },
                    {
                    message: 'Email ou CPF inválido, verifique o formato',
                    pattern: emailOuCPFRegex
                    }
                ]}
            >
                <FieldInput data-testid="inpt-email-cpf" placeholder="Digite seu email ou CPF" />
            </Form.Item>
            </Label>
            <Label>
              Senha
            <Form.Item
                name="password"
                style={{ margin: '25px 0' }}
                required={false}
                rules={[
                    {
                    required: true,
                    message: 'Campo obrigatório'
                    }
                ]}
                >
                <FieldInput data-testid="inpt-senha" type="password" placeholder="Digite sua senha" />
            </Form.Item>
            </Label>
            <Button
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#F26526",
                color: "white",
                height: "40px",
                margin: "10px 0px",
              }}
            >
              Renovar sessão
            </Button>
          </Form>
        </div>
      </Modal>
    );
}