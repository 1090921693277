import { post } from "@prev-front/shared-api"
import qs from 'qs'
import { API_AUTH } from "./constants"

interface LoginData {
    grant_type: string
    slug: string
    username: string
    password: string
  }

export interface IToken {
access_token: string
expires_in: number
refresh_token: string
token_type: string
}


export const login = async (data: LoginData): Promise<IToken> => {
    const authApp = `Basic ${API_AUTH}`
    const payload = {
      grant_type: data.grant_type || 'password',
      slug: data.slug,
      username: data.username,
      password: data.password
    }
  
    const response = await post('/oauth/token', qs.stringify(payload), {
      headers: {
        Authorization: authApp,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return response as IToken
  }
  