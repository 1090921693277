import { getToken } from "@prev-front/shared-api"

export const getInitials = (name: string | undefined): string => {
  if (!name) return ''
  const split = name.split('')
  return split?.length > 0 ? split[0] : ""
}

export const getSlugFromUrl = (): string | null => {
  const currentUrl = window.location.href
  const urlParts = currentUrl.split('//')
  const isLocal = checkLocalhost()

  if (urlParts.length > 1) {
    const domainAndPath = urlParts[1].split('/')
    const domainParts = domainAndPath[0].split('.')

    if (!isLocal && domainParts.length > 2) {
      return domainParts[0]
    }
    if (isLocal && domainParts.length > 1) {
      return domainParts[0]
    }
  }

  return null
}

export const checkLocalhost = (): boolean => {
  const currentUrl = window.location.href
  const isLocal = currentUrl.includes('localhost')
  return isLocal
}

export const checkBarraRouteException = (): string | undefined => {
  const listaException = [
    'invitation-accepted',
    'password-recovery',
    'login-escritorio',
    'migracao',
    'login',
    'criacaoConta',
    'checkout',
    'contribuicoes',
    'reativarAssinatura',
    'aula/',
    'pagarFatura',
    'meu-escritorio',
    'pagar-fatura'
  ]
  const temException = listaException.find((item) =>
    window.location.href.includes(item)
  )
  return temException
}

export const estadosOab = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' }
]

export const slugToUrl = (slug: string, reload?: boolean) => {
  // Extrai o domínio atual da URL
  const currentUrl = window.location.href
  const urlParts = currentUrl.split('//')
  const protocol = urlParts[0]
  const domain = urlParts[1].split('/')[0]

  // Atualiza a URL com o slug
  const newUrl = `${protocol}//${slug}.${domain}`
  if (!reload) {
    window.location.replace(newUrl)
    return
  }
  window.location.href = newUrl
}