import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, navigate as navigateReach } from '@reach/router'
import '../styles.css'
import {
  Layout,
  Menu,
  Button,
  Text,
  PrevLogo,
  Row,
  Col,
  Searchbar,
  Avatar,
  Dropdown,
  Modal,
  usePrevTheme,
  Badge,
  Divider,
  PrevPlatformProvider,
  Card,
  Space,
  Typography,
  Slider,
  Tooltip
} from '@prev/ui-kit'
import styled from 'styled-components'
import PaywallLogo1 from '../assets/prevCasosPaywall.svg'
import PaywallLogo2 from '../assets/prevCasosPaywall2.svg'
import IAIcon from "../assets/IA.svg"
import { useWidth } from "@prev-front/mf-core"

const moduleMap = {
  LAUDOS: 'Banco de laudos',
  CASOS: 'Prev Casos',
  DIRETORIO: 'Diretório de Advogados'
}

const moduleLink = {
  LAUDOS: 'https://previdenciarista.com/banco-de-laudos-produto/',
  CASOS: 'de Casos',
  DIRETORIO: 'do Diretório de Advogados'
}

const paywallText = {
  LAUDOS:
    'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.',
  CASOS:
    'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.',
  DIRETORIO:
    'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.'
}

const regexCasos = /^CASOS_\d+$/
const regexLaudos = /^LAUDOS_\d+$/

import {
  TeamOutlined,
  FileTextOutlined,
  HomeOutlined,
  BankOutlined,
  SolutionOutlined,
  PlusCircleOutlined,
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  WalletOutlined,
  MenuFoldOutlined,
  BookOutlined,
  UnorderedListOutlined,
  QuestionCircleOutlined,
  CalculatorOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
  MedicineBoxOutlined,
  FileSyncOutlined,
  AppstoreOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  DesktopOutlined,
  SketchOutlined,
  NotificationOutlined,
  BellOutlined,
  AuditOutlined,
  LaptopOutlined,
  BgColorsOutlined
} from '@ant-design/icons'
import { getInitials, getProfile, getSlugFromUrl } from '../utils'
import { getUserInfo } from '@prev-front/shared-api'
import { useSearch } from '../hooks/useSearch'
import { SearchContext } from '../contexts/SearchContext'
import { useNavigate, useLocation } from 'react-router-dom'
import { LoginModal } from '../components/LoginModal'
import { FormAnimationContext } from '../contexts/FormAnimationContext'

interface siderItems {
  icon: any
  displayName: string
  link?: string
  options?: any[]
  key?: string
}

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {

  }
`

const StyledSlider = styled(Slider)`
   .ant-slider-handle {
      display: none !important;
  }

  .ant-slider-track {
    background-color: ${(props) => props.theme.color.icon.brand}; /* Cor da linha preenchida */
    height: 4px; /* Altura da linha */
    border-radius: 4px;
}
`

const checkBarraRouteException = (): string | undefined => {
  const listaException = [
    'invitation-accepted',
    'password-recovery',
    'login-escritorio',
    'migracao',
    'login',
    'criacaoConta',
    'checkout',
    'contribuicoes',
    'reativarAssinatura',
    'aula/',
    'pagarFatura',
    'meu-escritorio',
    'pagar-fatura'
  ]
  const temException = listaException.find((item) =>
    window.location.href.includes(item)
  )
  return temException
}

interface ILayout {
  children: any
  siderWidth?: number
  siderItems?: siderItems[]
  onSearch?: (inputRef: any) => void
  onNewCalculation?: () => void
  profileBadgeCount?: number
  navigate?: any
}

const { Header, Content, Sider } = Layout
const StyledSider = styled(Sider)`
  && {
    padding: 0;
    border: none;
    min-height: 100vh;
    background-color: ${(props) => props.theme.color.surface.container.default};
  }
  .ant-menu-item-group-title {
    padding-left: 0;
  }
`
const StyledMenu = styled(Menu)`
  && .ant-menu-submenu-selected > .ant-menu-submenu-title,
  && .ant-menu-item-selected {
    border-radius: 2px 4px 4px 2px;
    height: 32px; /* Ajuste conforme necessário */
    color: ${(props) => props.theme.color.text.brand} !important;
    background-color: ${(props) => props.theme.color.background.disabled};
  }

  && .ant-menu-item-active:hover,
  && .ant-menu-submenu-title:hover,
  && .ant-menu-item:not(.ant-menu-item-selected):hover,
  &&
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ) {
    border-radius: 2px 4px 4px 2px;
    opacity: 0.6;
    height: 32px; /* Ajuste conforme necessário */
  }

  && .ant-menu-sub.ant-menu-inline {
    background: none;
  }

  .ant-menu-item {
    height: 32px; /* Ajuste conforme necessário */
    padding-left: 10px; /* Define o padding à esquerda */
    margin-bottom: 4px;
    border-radius: 4px;
  }

  .ant-menu-submenu-title {
    padding-left: 10px; /* Define o padding à esquerda para títulos de submenu */
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title,
  .ant-menu-item-selected {
    border-left: 2px solid ${(props) => props.theme.color.text.brand};
  }
`;

const StyledContent = styled(Content)`
  padding: 16px 12px 16px 18px;
`

export const PrevLayout = ({
  children,
  siderWidth = 264,
  siderItems = [
    { icon: HomeOutlined, displayName: 'Painel', link: '/', key: '/' },
    {
      icon: TeamOutlined,
      displayName: 'Clientes',
      link: '/clientes',
      key: '/clientes'
    },
    {
      icon: FileTextOutlined,
      displayName: 'Busca de petições',
      link: '/peticoes',
      key: '/peticoes'
    },
    {
      icon: BankOutlined,
      displayName: 'Busca de jurisprudência',
      link: '/jurisprudencia',
      key: '/jurisprudencia'
    },
    {
      icon: FileSyncOutlined,
      displayName: 'Busca de laudos',
      link: '/laudos',
      key: '/laudos'
    },
    {
      icon: SolutionOutlined,
      displayName: 'Casos',
      link: '/casos',
      key: '/casos'
    },
    {
      icon: UnorderedListOutlined,
      displayName: 'Meu diretório',
      link: '/meu-escritorio',
      key: '/meu-escritorio'
    },
    {
      icon: BookOutlined,
      displayName: 'Cursos',
      link: '/cursos',
      key: '/cursos'
    },
    {
      icon: QuestionCircleOutlined,
      displayName: 'Central de ajuda',
      link: 'https://ajuda.previdenciarista.com/',
      key: 'https://ajuda.previdenciarista.com/'
    }
  ],
  onSearch,
  onNewCalculation,
  profileBadgeCount,
  navigate = null
}: ILayout): JSX.Element => {
  const [selected, setSelected] = useState<string[]>(['/'])
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 1200)
  const [userInfo, setUserInfo] = useState<any | null>()
  const [barContent, setBarContent] = useState<JSX.Element | undefined>()
  const [modules, setModules] = useState<string[]>([])
  const {
    actualPath,
    handleFindBy,
    query,
    setActualPath,
    setQuery,
    searchQuery,
    setSearchQuery
  } = useSearch()
  const containerRef = useRef<HTMLDivElement>(null) // Adicionar referência ao contêiner
  const [paywall, setPaywall] = useState({
    open: false,
    reason: ''
  })
  const [statusOk, setStatusOk] = useState(true)
  const [checkedSlug, setCheckedSlug] = useState(false)
  const navigateDom = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const { darkMode, theme } = usePrevTheme()
  const { calcView } = useContext(FormAnimationContext)
  const width = useWidth()

  const checkSlug = () => {
    const hasSlug = getSlugFromUrl()
    if (hasSlug || (userInfo && !userInfo.escritorio)) {
      setCheckedSlug(true)
    }
    if (!hasSlug && userInfo && userInfo.escritorio?.slug) {
      alert('aqui')
      navigateReach('/login')
    }
  }

  useEffect(() => {
    checkSlug()
  }, [userInfo])

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.backgroundColor =
      theme.color.surface.container.default
  }, [darkMode])

  const scrollTop = (): void => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  const menuItemStyle = (key) => {
    return (
      {
        color: selected.includes(key) ? theme.color.text.brand : theme.color.text.subtlest,
        display: 'flex',
        alignItems: 'center',
        fontWeight: selected.includes(key) ? 600 : 400
      }
    )
  };

  const iconStyle = (key) => ({
    color: selected.includes(key) ? theme.color.text.brand : theme.color.icon.subtle,
    fontSize: "18px",
    marginRight: "4px"
  });

  const items = siderItems.map(
    ({ icon, displayName, options, link, key }, index) => {
      const MenuIcon = icon
      return {
        key: key ?? index,
        icon: <MenuIcon style={{ color: theme.color.text.default }} />,
        label: (
          <a
            onClick={() => {
              if (link === 'https://ajuda.previdenciarista.com/') {
                window.open(link, '_blank')
                return
              }
              if (!link || !navigateDom) return
              link === '/meu-escritorio'
                ? window.open('/meu-escritorio', '_blanck')
                : navigateDom(link ?? '')
            }}
          >
            <Text
              style={{
                color:
                  selected[0] === key
                    ? theme.color.text.brand
                    : theme.color.text.subtlest
              }}
              emphasis={selected[0] === key}
            >
              {displayName}
            </Text>
          </a>
        ),
        children: options?.map(({ displayName }, j) => {
          const subKey = index * 4 + j + 1
          return {
            key: subKey,
            label: (
              <Text
                onClick={() => {
                  if (link === 'https://ajuda.previdenciarista.com/') {
                    window.open(link, '_blank')
                    return
                  }
                  navigateDom(link ?? '')
                }}
              >
                {displayName}
              </Text>
            )
          }
        })
      }
    }
  )

  const setupProfile = async (): Promise<void> => {
    try {
      const data = await getProfile()
      const userInfo = await getUserInfo()
      console.log("userInfo", userInfo)
      const modules = userInfo?.assinatura.tiposDeModulo
      if (modules?.length) {
        setModules(modules)
      }
      setUserInfo(data as unknown as any)
      void renderBarras()
    } catch (err) {
      console.log('Erro ao buscar informações do usuário', err)
    }
  }

  const checkPaywall = (module?: string): boolean => {
    if (!statusOk) {
      setPaywall({
        open: true,
        reason: 'PAYMENT'
      })
      return statusOk
    }
    let hasModule = module ? false : true
    if (module && modules.some((m) => m.startsWith(module))) {
      hasModule = true
    }
    if (!hasModule) {
      setPaywall({
        open: true,
        reason: module || 'PAYMENT'
      })
    }
    return statusOk && hasModule
  }

  useEffect(() => {
    const currentUrl = pathname
    if (
      [
        '/peticoes',
        '/jurisprudencia',
        '/clientes',
        '/laudos',
        '/casos',
        '/cursos',
        '/'
      ].includes(currentUrl)
    ) {
      setSelected([currentUrl])
      setActualPath(currentUrl)
    }
  }, [pathname])

  const renderBarras = async (): Promise<JSX.Element | null | undefined> => {
    try {
      const info = await getUserInfo()
      if (!info?.assinatura || checkBarraRouteException()) return null
      const { status, acesso } = info.assinatura
      const suspenso = status === 'ATIVA' && acesso === 'SUSPENSO'
      const cancelada = status === 'CANCELADA' && acesso !== 'ATIVO'
      if (window.location.pathname.includes('/casos/')) return
      if (suspenso || cancelada) {
        setStatusOk(false)
      }
      if (suspenso) {
        setBarContent(
          <Row
            style={{
              width: '100%',
              backgroundColor: '#ec1051',
              gap: '25px',
              height: '70px'
            }}
            justify="center"
            align="middle"
          >
            <Text style={{ color: 'white' }}>
              Atenção! Seu plano possui pendências financeiras. Corrija para
              restabelecer o acesso.
            </Text>
            <Button
              style={{ color: 'white' }}
              type="secondary"
              onClick={() => {
                navigateReach('/configuracoes/financeiro')
              }}
            >
              Visualizar pendências
            </Button>
          </Row>
        )
      } else if (cancelada) {
        setBarContent(
          <Row
            style={{
              width: '100%',
              backgroundColor: '#ff8822',
              gap: '25px',
              height: '70px'
            }}
            justify="center"
            align="middle"
          >
            <Text style={{ color: 'white' }}>
              Atenção! Você não tem uma assinatura ativa. Reative sua assinatura
              aqui para restabelecer seu acesso.
            </Text>
            <Button
              style={{ color: 'white' }}
              type="secondary"
              onClick={() => {
                navigateReach('/configuracoes/financeiro')
              }}
            >
              Ativar assinatura
            </Button>
          </Row>
        )
      }
    } catch (err) {
      console.log('Erro ao verificar status assinatura')
    }
  }

  const calculoDropdown = (
    <Menu data-testid='menu-calculo-dropdown'>
      <Menu.Item
        title={null}
        key="1"
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/calculos/beneficio')}
      >
        <Col>
          <Row style={{ gap: '5px' }}>
            <CalculatorOutlined />
            <Text emphasis>Benefícios previdenciários</Text>
          </Row>
          <Text>Faça o envio do CNIS ou inicie o cálculo manualmente</Text>
        </Col>
      </Menu.Item>
      <Menu.Item
        title={null}
        key="2"
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/calculos/liquidacao')}
      >
        <Col>
          <Row style={{ gap: '5px' }}>
            <FileDoneOutlined />
            <Text emphasis>Liquidação de sentença</Text>
          </Row>
          <Text>Realize um cálculo dos benefícios atrasados</Text>
        </Col>
      </Menu.Item>
      <Menu.Item
        title={null}
        key="3"
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/calculos/valor-causa')}
      >
        <Col>
          <Row style={{ gap: '5px' }}>
            <DollarCircleOutlined />
            <Text emphasis>Valor da causa</Text>
          </Row>
          <Text>Realize um cálculo do valor da causa</Text>
        </Col>
      </Menu.Item>
      <Menu.Item
        key="4"
        title={null}
        style={{ padding: '16px' }}
        onClick={() => navigateReach('/beneficio-por-incapacidade')}
      >
        <Col>
          <Row style={{ gap: '5px' }}>
            <MedicineBoxOutlined />
            <Text emphasis>Benefício por incapacidade</Text>
          </Row>
          <Text>
            Fluxo de concessão ou restabelecimento de benefícios por incapacidade
            para processo eletrônico
          </Text>
        </Col>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    void setupProfile()
  }, [])

  const handleSelect = ({ key }) => {
    setSelected([key]);
  };

  return (
    <SearchContext.Provider
      value={{
        query,
        setQuery,
        setSearchQuery,
        actualPath,
        setActualPath,
        handleFindBy,
        scrollTop,
        checkPaywall,
        searchQuery
      }}
    >
      <Layout
        className="layoutPrev"
        style={{ position: 'fixed', width: '100%', zIndex: 50 }}
      >
        <Header
          style={{
            height: barContent ? '140px' : '64px',
            backgroundColor: theme.color.surface.container.default,
            borderBottom: `1px solid ${theme.color.border.default}`,
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {barContent}

          {/* Logo e Nome da Aplicação */}

          <Space align="center">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PrevLogo />
            </div>
          </Space>

          {/* Barra de Busca */}
          <Row align='middle' wrap={false}>
            <Searchbar
              data-testid="inpt-header-searchbar"
              actualPath={actualPath as '/'}
              clearSearch={() => {
                setQuery('')
                setSearchQuery("")
              }}
              width={width < 1280 ? '420px' : "530px"}
              handleChange={setQuery}
              value={query}
              searchQuery={query}
              setActualPath={setActualPath}
              showModal={() => true}
              showHistory={true}
              findBy={handleFindBy}
            />
            <Card style={{
              borderRadius: "4px", marginLeft: '8px', border: "none", boxShadow: "0px 1px 2px 1px rgba(39, 39, 46, 0.25), 0px 0px 1px 0px rgba(39, 39, 46, 0.31)",
            }} styles={{ body: { padding: "8px", width: "50px", height: "40px", } }} data-testid='card-buca-ai' aria-label=''>
              <Row>
                <img src={IAIcon} style={{ width: "20px", height: "20px" }} />
                <Text>AI</Text>
              </Row>
            </Card>
          </Row>


          <Row style={{ width: '320px', gap: '24px' }} justify="end" align='middle' wrap={false}>
            <Col>
              <Badge style={{ color: '#FDFDFF', backgroundColor: theme.color.background.danger.bold.default, fontSize: "12px" }} data-testid='count-notificacoes' count={25}>
                <BellOutlined style={{ fontSize: '16px' }} />
              </Badge>
            </Col>
            <Col>
              <Dropdown
                data-testid='dropdown-configuracoes-header'
                overlayStyle={{ cursor: "pointer" }}
                overlay={() => (
                  <Menu
                    data-testid='menu-configuracoes-header'
                    style={{ backgroundColor: theme.color.surface.default }}
                  >
                    <Menu.Item key="1" data-testid="btn-header-menu-conta">
                      <Link to="/configuracoes/conta">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <UserOutlined />
                          <Text>Minha conta</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" data-testid="btn-header-menu-financeiro">
                      <Link to="/configuracoes/financeiro">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <WalletOutlined />
                          <Text>Meu financeiro</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      data-testid="btn-header-menu-configuracoes"
                    >
                      <Link to="/configuracoes">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <WalletOutlined />
                          <Text>Configurações do escritório</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      data-testid="btn-header-menu-sair"
                      key="4"
                      onClick={() => {
                        localStorage.removeItem('access_token_prev')
                        navigateReach('/login')
                      }}
                    >
                      <Row
                        style={{ gap: '5px', color: theme.color.text.default }}
                      >
                        <LogoutOutlined />
                        <Text>Sair do sistema</Text>
                      </Row>
                    </Menu.Item>
                  </Menu>
                )}
              >
                <Row align='middle' wrap={false} style={{ cursor: "pointer" }}>
                  <Col>
                    <Avatar
                      data-testid="tag-casos-1"
                      aria-label="avatar-l"
                      style={{
                        backgroundColor:
                          theme.color.background.accent.brand.subtlest.default,
                        color: theme.color.text.brand,
                        width: '32px',
                      }}
                    >
                      {getInitials(userInfo?.usuario.nome ?? '')}
                    </Avatar>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginLeft: '8px',
                    }}
                  >
                    <Typography style={{ color: theme.color.text.default }}>
                      {userInfo?.usuario.nome} <DownOutlined />
                    </Typography>
                    <Typography
                      style={{ color: theme.color.text.subtlest, fontSize: '12px' }}
                    >
                      {userInfo?.escritorio?.nome}
                    </Typography>
                  </Col>
                </Row>
              </Dropdown>
            </Col>
          </Row>

          {/* <Row
            style={{
              width: '100%',
              height: '64px',
              paddingRight: '10px',
              paddingLeft: '10px'
            }}
            align="middle"
            justify="space-between"
          >
            <Col span={2}>
              <PrevLogo />
            </Col>
            <Col span={17}>
              <Row justify='center' align="middle" style={{ width: '100%' }}>
                <Searchbar
                  data-testid="inpt-header-searchbar"
                  actualPath={actualPath as '/'}
                  clearSearch={() => {
                    setQuery('')
                    setSearchQuery("")
                  }}
                  handleChange={setQuery}
                  value={query}
                  searchQuery={query}
                  setActualPath={setActualPath}
                  showModal={() => true}
                  showHistory={true}
                  findBy={handleFindBy}
              />
                <Card style={{ width: "50px", height: "40px", borderRadius: '4px', marginLeft: "8px", boxShadow: "0px 1px 2px 1px rgba(39, 39, 46, 0.25), 0px 0px 1px 0px rgba(39, 39, 46, 0.31)" }} styles={{ body: { padding: "8px" } }} data-testid='card-ia-busca' aria-label=''>
                  <Row>
                    <img src={IAIcon} alt='Icone para busca com IA' />
                    <Text>IA</Text>
                  </Row>
                </Card>
              </Row>
            </Col>
            <Col span={5}>
              <Dropdown
                data-testid='dropdown-configuracoes-header'
                overlay={() => (
                  <Menu
                    data-testid='menu-configuracoes-header'
                    style={{ backgroundColor: theme.color.surface.default }}
                  >
                    <Menu.Item key="1" data-testid="btn-header-menu-conta">
                      <Link to="/configuracoes/conta">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <UserOutlined />
                          <Text>Minha conta</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" data-testid="btn-header-menu-financeiro">
                      <Link to="/configuracoes/financeiro">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <WalletOutlined />
                          <Text>Meu financeiro</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      data-testid="btn-header-menu-configuracoes"
                    >
                      <Link to="/configuracoes">
                        <Row
                          style={{
                            gap: '5px',
                            color: theme.color.text.default
                          }}
                        >
                          <WalletOutlined />
                          <Text>Configurações do escritório</Text>
                        </Row>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      data-testid="btn-header-menu-sair"
                      key="4"
                      onClick={() => {
                        localStorage.removeItem('access_token_prev')
                        navigateReach('/login')
                      }}
                    >
                      <Row
                        style={{ gap: '5px', color: theme.color.text.default }}
                      >
                        <LogoutOutlined />
                        <Text>Sair do sistema</Text>
                      </Row>
                    </Menu.Item>
                  </Menu>
                )}
              >
                <Row gutter={12} align="middle" justify="end" wrap={false}>
                  <Col>
                    <Avatar
                      aria-label='aria-avatar'
                      src={userInfo?.usuario?.urlImagemPerfil}
                      size={32}
                      data-testid="header-avatar-usuario"
                      shape="circle"
                      style={{ cursor: 'pointer' }}
                    >
                      {getInitials(userInfo?.usuario.nome ?? '')}
                    </Avatar>
                  </Col>
                  <Col style={{ height: '100%', cursor: 'pointer' }}>
                    <Row
                      style={{
                        justifyContent: 'space-between',
                        marginBottom: '10px'
                      }}
                    >
                      <Text
                        style={{ marginRight: '5px' }}
                        data-testid="header-nome-usuario"
                      >
                        {userInfo?.usuario.nome}
                      </Text>
                      <DownOutlined
                        style={{ color: theme.color.text.default }}
                      />
                    </Row>
                    <Row>
                      <Text
                        data-testid="header-nome-escritorio"
                        color={theme.color.text.subtle}
                      >
                        {userInfo?.escritorio?.nome}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Dropdown>
            </Col>
          </Row> */}
        </Header>
        <Layout>
          <StyledSider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={siderWidth}
            style={{
              borderRight: `1px solid ${theme.color.border.default}`,
              backgroundColor: theme.color.surface.container.default
            }}
          >
            <div
              style={{
                display: 'flex',
                height: 'max-content',
                justifyContent: 'center',
                marginTop: '15px'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '17px',
                  zIndex: 50,
                  right: collapsed ? "-25px" : '-20px',
                  backgroundColor: theme.color.surface.container.default,
                  padding: '8px',
                  boxShadow:
                    '0px 8px 12px 0px rgba(39, 39, 46, 0.15), 0px 0px 1px 0px rgba(39, 39, 46, 0.30)',
                  borderRadius: '4px',
                  border: `1px solid ${theme.color.border.default}`,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setCollapsed(!collapsed)
                }}
              >
                <MenuFoldOutlined
                  style={{ color: theme.color.text.default, fontSize: '16px' }}
                />
              </div>
                <Button
                  type="primary"
                  style={{
                    height: '48px',
                    display: 'flex',
                    margin: "0 26px",
                    width: "210px",
                    gap: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none'
                  }}
                  onClick={() => {
                    calcView.setShowCalcTab(true)
                  }}
                >
                  <PlusCircleOutlined style={{ color: '#FDFDFF', fontSize: '20px', fontWeight: 400 }} />
                  {!collapsed && (
                    <Text color="#FDFDFF" style={{ fontWeight: 400, fontSize: '20px' }}>
                      Novo cálculo
                    </Text>
                  )}
              </Button>
            </div>
            <StyledMenu
              style={{
                marginTop: 10,
                padding: "0 16px",
                border: 'none',
                backgroundColor: theme.color.surface.container.default
              }}
              onSelect={handleSelect}
              selectedKeys={selected}
              data-testid='menu-layout'
              mode="inline"

              defaultSelectedKeys={['1']}
            >
              <Menu.Item title={null} onClick={() => navigateDom('/')} style={{ paddingLeft: '12px' }} key="/"
                icon={
                  collapsed ? <StyledTooltip
                    arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }}
                    data-testid='tooltip-menu-dashboard' placement='right' title='Dashboard'>
                    <AppstoreOutlined style={iconStyle('/')} />
                  </StyledTooltip> :
                    <AppstoreOutlined style={iconStyle('/')} />
                }
              >
                <Text style={menuItemStyle('/')}>Dashboard</Text>
              </Menu.Item>
              <Menu.Item title={null} onClick={() => navigateDom('/clientes')} style={{ paddingLeft: '12px' }} key="/clientes"
                icon={
                  collapsed ? <StyledTooltip arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }} data-testid='tooltip-menu-clientes' placement='right' title='Clientes'>
                    <UserOutlined style={iconStyle('/clientes')} />
                  </StyledTooltip> :
                    <UserOutlined style={iconStyle('/clientes')} />
                }
              >
                <Text style={menuItemStyle('/clientes')}>Clientes</Text>
              </Menu.Item>
              <Menu.ItemGroup title={<Text style={{ fontSize: "12px", color: "#7C7B84" }}>Buscas</Text>}>
                <Menu.Item title={null} onClick={() => navigateDom('/peticoes')} style={{ paddingLeft: '12px' }} key="/peticoes"
                  icon={
                    collapsed ? <StyledTooltip arrow={false}
                      overlayInnerStyle={{
                        backgroundColor: theme.color.surface.default,
                        color: theme.color.text.subtle,
                        fontSize: "12px",
                        padding: '8px',
                        lineHeight: "150%"
                      }} data-testid='tooltip-menu-peticoes' placement='right' title='Petições'>
                      <FileSearchOutlined style={iconStyle('/peticoes')} />
                    </StyledTooltip> :
                      <FileSearchOutlined style={iconStyle('/peticoes')} />
                  }
                >
                  <Text style={menuItemStyle('/peticoes')}>Busca de Petições</Text>
                </Menu.Item>
                <Menu.Item title={null} onClick={() => navigateDom('/laudos')} style={{ paddingLeft: '12px' }} key="/laudos"
                  icon={
                    collapsed ? <StyledTooltip arrow={false}
                      overlayInnerStyle={{
                        backgroundColor: theme.color.surface.default,
                        color: theme.color.text.subtle,
                        fontSize: "12px",
                        padding: '8px',
                        lineHeight: "150%"
                      }} placement='right' title='Laudos' data-testid='tooltip-menu-laudos'>
                      <AuditOutlined style={iconStyle('/laudos')} />
                    </StyledTooltip> :
                      <AuditOutlined style={iconStyle('/laudos')} />
                  }
                >
                  <Text style={menuItemStyle('/laudos')}>Busca de Laudos</Text>
                </Menu.Item>
                <Menu.Item title={null} onClick={() => navigateDom('/jurisprudencia')} style={{ paddingLeft: '12px' }} key="/jurisprudencia"
                  icon={
                    collapsed ? <StyledTooltip arrow={false}
                      overlayInnerStyle={{
                        backgroundColor: theme.color.surface.default,
                        color: theme.color.text.subtle,
                        fontSize: "12px",
                        padding: '8px',
                        lineHeight: "150%"
                      }} data-testid='tooltip-menu-jurisprudencia' placement='right' title='Jurisprudências'>
                      <BankOutlined style={iconStyle('/jurisprudencia')} />
                    </StyledTooltip> :
                      <BankOutlined style={iconStyle('/jurisprudencia')} />
                  }
                >
                  <Text style={menuItemStyle('/jurisprudencia')}>Busca de Jurisprudências</Text>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title={<Text style={{ fontSize: "12px", color: "#7C7B84" }}>Módulos</Text>}>
                <Menu.Item title={null} onClick={() => navigateDom('/casos')} style={{ paddingLeft: '12px' }} key="/casos"
                  icon={
                    collapsed ? <StyledTooltip arrow={false}
                      overlayInnerStyle={{
                        backgroundColor: theme.color.surface.default,
                        color: theme.color.text.subtle,
                        fontSize: "12px",
                        padding: '8px',
                        lineHeight: "150%"
                      }} data-testid='tooltip-menu-casos' placement='right' title='Casos'>
                      <SolutionOutlined style={iconStyle('/casos')} />
                    </StyledTooltip> :
                      <SolutionOutlined style={iconStyle('/casos')} />
                  }
                >
                  <Text style={menuItemStyle('/casos')}>PrevCasos</Text>
                </Menu.Item>
                <Menu.Item title={null} onClick={() => window.open('/meu-escritorio', '_blank')} style={{ paddingLeft: '12px' }} key="/diretorio"
                  icon={
                    collapsed ? <StyledTooltip arrow={false}
                      overlayInnerStyle={{
                        backgroundColor: theme.color.surface.default,
                        color: theme.color.text.subtle,
                        fontSize: "12px",
                        padding: '8px',
                        lineHeight: "150%"
                      }} data-testid='tooltip-menu-diretorio' placement='right' title='Meu diretório'>
                      <LaptopOutlined style={iconStyle('/diretorio')} />
                    </StyledTooltip> :
                      <LaptopOutlined style={iconStyle('/diretorio')} />
                  }
                >
                  <Text style={menuItemStyle('/diretorio')}>Meu Diretório</Text>
                </Menu.Item>
              </Menu.ItemGroup>
              <Divider style={{ padding: 0, margin: "8px 0" }} />
              <Menu.Item title={null} onClick={() => navigateDom('/cursos')} style={{ paddingLeft: '12px' }} key="/cursos"
                icon={
                  collapsed ? <StyledTooltip arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }} data-testid='tooltip-menu-cursos' placement='right' title='Cursos'>
                    <BookOutlined style={iconStyle('/cursos')} />
                  </StyledTooltip> :
                    <BookOutlined style={iconStyle('/cursos')} />
                }
              >
                <Text style={menuItemStyle('/cursos')}>Cursos</Text>
              </Menu.Item>
              {
                !collapsed && (
                  <Card data-testid='card-consumo-casos' aria-label='' style={{ marginTop: "28px" }} styles={{ body: { paddingBottom: 0 } }}>
                    <Text emphasis style={{ fontSize: "14px", marginBottom: '8px' }}>Faça já um upgrade!</Text>
                    <Text style={{ fontSize: "12px", color: theme.color.text.subtle, marginBottom: "8px" }}>
                      Faltam apenas 3 casos para atingir o limite máximo do seu plano atual.
                    </Text>
                    <StyledSlider data-testid='' value={7} max={10} />
                  </Card>
                )
              }
            </StyledMenu>
          </StyledSider>
          <StyledContent
            ref={containerRef}
            style={{
              overflowY: 'scroll',
              height: '100vh',
              paddingBottom: '100px',
              backgroundColor: theme.color.surface.default
            }}
          >
            <span id="prev-container">
              <Modal
                width={600}
                data-testid='paywall-modal'
                title={
                  paywall.reason !== 'PAYMENT' ? (
                    <Text>
                      Faça um upgrade em seu plano para ter acesso a este
                      conteúdo
                    </Text>
                  ) : (
                    'Atenção'
                  )
                }
                closable={true}
                open={paywall.open}
                onClose={() =>
                  setPaywall({
                    open: false,
                    reason: ''
                  })
                }
                onCancel={() =>
                  setPaywall({
                    open: false,
                    reason: ''
                  })
                }
                footer={
                  paywall.reason === 'PAYMENT'
                    ? [
                      <Button
                        key="painelPaywall"
                        type="subtle"
                        onClick={() => {
                          setPaywall({
                            open: false,
                            reason: ''
                          })
                          navigateReach('/')
                        }}
                      >
                        Voltar para o painel
                      </Button>,
                      <Button
                        key="financeiroPaywall"
                        style={{ color: 'white' }}
                        type="primary"
                        onClick={() => {
                          setPaywall({
                            open: false,
                            reason: ''
                          })
                          navigateReach('/configuracoes/financeiro')
                        }}
                      >
                        Regularizar pendência
                      </Button>
                    ]
                    : [
                      <Button
                        key="painelPaywall"
                        type="primary"
                        style={{ color: 'white' }}
                        onClick={() => {
                          setPaywall({
                            open: false,
                            reason: ''
                          })
                          navigateReach('/configuracoes/financeiro')
                        }}
                      >
                        Configurar meu plano
                      </Button>,
                      <Button
                        key="financeiroPaywall"
                        style={{ color: 'white' }}
                        type="secondary"
                        onClick={() => {
                          setPaywall({
                            open: false,
                            reason: ''
                          })
                          window.open(moduleLink[paywall.reason], '_blank')
                        }}
                      >
                        Conheça {moduleMap[paywall.reason]}
                      </Button>
                    ]
                }
              >
                {paywall.reason === 'PAYMENT' && (
                  <Col style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <img
                      src={
                        paywall.reason !== 'PAYMENT'
                          ? PaywallLogo2
                          : PaywallLogo1
                      }
                    />
                    <Text
                      emphasis
                      style={{ marginBottom: '20px', marginTop: '20px' }}
                      size="h6"
                    >
                      Seu plano atual está suspenso por problemas financeiros.
                    </Text>
                    <Text style={{ marginBottom: '30px' }} emphasis>
                      Para que você possa utilizar nosso sistema normalmente,
                      precisamos que regularize esta pendência.
                    </Text>
                    <Text style={{ marginBottom: '10px' }}>
                      Isso pode ter acontecido porque:
                    </Text>
                    <Text color={theme.color.text.subtlest}>
                      1. Há algum problema com seu banco;
                    </Text>
                    <Text color={theme.color.text.subtlest}>
                      2. O cartão informado para pagamento está vencido;
                    </Text>
                    <Text color={theme.color.text.subtlest}>
                      3. Não há saldo suficiente em sua conta;
                    </Text>
                  </Col>
                )}
                {paywall.reason !== 'PAYMENT' && (
                  <Col style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <img
                      src={
                        paywall.reason !== 'PAYMENT'
                          ? PaywallLogo2
                          : PaywallLogo1
                      }
                    />
                    <Text
                      emphasis
                      style={{ marginBottom: '20px', marginTop: '20px' }}
                      size="h6"
                    >
                      Você não possui o módulo do {moduleMap[paywall.reason]}
                    </Text>
                    <Text style={{ marginBottom: '30px' }} emphasis>
                      {paywallText[paywall.reason]}
                    </Text>
                  </Col>
                )}
              </Modal>
              {checkedSlug && children}
            </span>
          </StyledContent>
        </Layout>
      </Layout>
    </SearchContext.Provider>
  )
}
