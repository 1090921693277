import { Drawer, usePrevTheme, Form, Row, Divider, Button, Col, PrevLogo } from "@prev/ui-kit"
import React from "react"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { CalcView } from "./CalcView"


interface DrawerSelecionarCalculoProps {
    open: boolean
    setOpen: (boolean: boolean) => void
}

const DrawerSelecionarCalculo = (props: DrawerSelecionarCalculoProps) => {
    const { open, setOpen, } = props
    const { theme } = usePrevTheme()

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Drawer
            data-testid="drawer-prev-caso"
            open={open}
            footer={false}
            title={
                <Row justify='center' align='middle' style={{ boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.10)' }}>
                    <Row justify="space-between" align="middle" style={{ width: '100%', maxWidth: "1366px", height: "64px" }}>
                        <Col style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: "16px" }}>
                            <Button style={{ padding: 0, }} type="subtle" icon={<ArrowLeftOutlined />} onClick={() => handleClose()}>
                                Voltar
                            </Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                            <PrevLogo />
                        </Col>
                    </Row>
                </Row>
            }
            closeIcon={false}
            onClose={handleClose}
            maskClosable={false}
            width="100vw"
            styles={{
                header: {
                    border: 'none',
                    padding: "8px 0"
                },
                body: {
                    padding: '28px 16px 28px 16px',
                    paddingBottom: 0,
                    backgroundColor: theme.color.surface.default,
                    overflowX: 'hidden'
                },
                footer: {
                    backgroundColor: theme.color.background.input.default,
                }
            }}
        >
            <Row justify='center'>
                <CalcView />
            </Row>
        </Drawer>
    )
}

export default DrawerSelecionarCalculo