import React, { createContext, useEffect, useState, ReactNode } from "react";
import { checkBarraRouteException } from "../utils";
import { getProfile, getUserInfo } from "@prev-front/shared-api";
import { Button, Col, Message, Modal, Row, Text, usePrevTheme } from "@prev/ui-kit";
import { useNavigate } from "react-router-dom"
import { ObjetoUserProfile, PrevCasosConsumoInterface, UserContextInterface } from "./UserContextTypes";
import PaywallLogo1 from '../assets/prevCasosPaywall.svg'
import PaywallLogo2 from '../assets/prevCasosPaywall2.svg'
import { getPrevCasosConsumo } from "../services";
import { UserInfoResponse } from "@prev-front/shared-api/src/services";

export const UserContext = createContext<UserContextInterface>({} as UserContextInterface);

interface UserProviderProps {
    children: ReactNode;
}

const moduleMap = {
    LAUDOS: 'Banco de laudos',
    CASOS: 'Prev Casos',
    DIRETORIO: 'Diretório de Advogados'
}

const moduleLink = {
    LAUDOS: 'https://previdenciarista.com/banco-de-laudos-produto/',
    CASOS: 'de Casos',
    DIRETORIO: 'do Diretório de Advogados'
}

const paywallText = {
    LAUDOS:
        'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.',
    CASOS:
        'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.',
    DIRETORIO:
        'Para ter acesso ao Banco de Laudos e poder fazer download dos laudos, adicione agora mesmo o serviço em sua assinatura e facilite sua busca por laudos paradigmas para processos previdenciários.'
}

const UserProvider = ({ children }: UserProviderProps) => {
    const [userInfo, setUserInfo] = useState<UserInfoResponse>();
    const [userProfile, setUserProfile] = useState<ObjetoUserProfile>();
    const [modules, setModules] = useState<string[]>([]);
    const [barContent, setBarContent] = useState<JSX.Element>()
    const [consumoModulos, setConsumoModulos] = useState<PrevCasosConsumoInterface>()

    const navigate = useNavigate()
    const { theme } = usePrevTheme()
    const [paywall, setPaywall] = useState({
        open: false,
        reason: ''
    })

    useEffect(() => {
        setupProfile();
        fetchCasesConsumption()
    }, []);

    const fetchCasesConsumption = async (): Promise<void> => {
        try {
            const casesConsumption = await getPrevCasosConsumo()
            setConsumoModulos(casesConsumption)
        } catch (err) {
            Message.error(err.message ?? "Algo deu errado ao pesquisar informações sobre consumo")
        }
    }

    const refetchUserInfo = async () => {
        const userInfoResponse = await getUserInfo();
        const modules = userInfoResponse?.assinatura?.tiposDeModulo || [];
        setModules(modules);
        setUserInfo(userInfoResponse)
    }

    const refetchUserProfile = async () => {
        const userProfileResponse = await getProfile();
        setUserProfile(userProfileResponse as unknown as ObjetoUserProfile);
    }

    const refetchConsumo = async () => fetchCasesConsumption()


    const checkPaywall = (module?: string): boolean => {
        if (!userInfo?.assinatura) return true
        const { status, acesso } = userInfo?.assinatura
        const suspenso = status === 'ATIVA' && acesso === 'SUSPENSO'
        const cancelada = status === 'CANCELADA' && acesso !== 'ATIVO'
        if (suspenso || cancelada) {
            setPaywall({
                open: true,
                reason: 'PAYMENT'
            })
            return false
        }
        let hasModule = module ? false : true
        if (module && modules.some((m) => m.startsWith(module))) {
            hasModule = true
        }
        if (!hasModule) {
            setPaywall({
                open: true,
                reason: module || 'PAYMENT'
            })
        }
        return true && hasModule
    }

    const setupProfile = async (): Promise<void> => {
        try {
            await refetchUserProfile()
            await refetchUserInfo()
        } catch (err) {
            console.error("Erro ao buscar informações do usuário", err);
        }
    };

    useEffect(() => {
        if (userInfo) renderBarras()
    }, [userInfo])

    const renderBarras = async (): Promise<JSX.Element | null | undefined> => {
        try {
            if (!userInfo?.assinatura || checkBarraRouteException()) return null
            const { status, acesso } = userInfo.assinatura
            const suspenso = status === 'ATIVA' && acesso === 'SUSPENSO'
            const cancelada = status === 'CANCELADA' && acesso !== 'ATIVO'
            if (window.location.pathname.includes('/casos/')) return
            if (suspenso) {
                setBarContent(
                    <Row
                        style={{
                            width: '100%',
                            backgroundColor: '#ec1051',
                            gap: '25px',
                            height: '70px'
                        }}
                        justify="center"
                        align="middle"
                    >
                        <Text style={{ color: 'white' }}>
                            Atenção! Seu plano possui pendências financeiras. Corrija para
                            restabelecer o acesso.
                        </Text>
                        <Button
                            style={{ fontSize: "14px", boxShadow: "none" }}
                            variant='secondary'
                            onClick={() => {
                                navigate('/configuracoes/financeiro')
                            }}
                        >
                            Visualizar pendências
                        </Button>
                    </Row>
                )
                return
            } else if (cancelada) {
                setBarContent(
                    <Row
                        style={{
                            width: '100%',
                            backgroundColor: '#ff8822',
                            gap: '25px',
                            height: '70px'
                        }}
                        justify="center"
                        align="middle"
                    >
                        <Text style={{ color: 'white' }}>
                            Atenção! Você não tem uma assinatura ativa. Reative sua assinatura
                            aqui para restabelecer seu acesso.
                        </Text>
                        <Button
                            style={{ color: 'white' }}
                            type="secondary"
                            onClick={() => {
                                navigate('/configuracoes/financeiro')
                            }}
                        >
                            Ativar assinatura
                        </Button>
                    </Row>
                )
                return
            }
        } catch (err) { }
    }

    return (
        <UserContext.Provider value={{ userInfo, userProfile, modules, setModules, setUserInfo, setUserProfile, checkPaywall, refetchUserInfo, refetchUserProfile, consumoModulos, refetchConsumo, barContent }}>
            <Modal
                width={600}
                data-testid='paywall-modal'
                zIndex={999999}
                title={
                    paywall.reason !== 'PAYMENT' ? (
                        <Text>
                            Faça um upgrade em seu plano para ter acesso a este
                            conteúdo
                        </Text>
                    ) : (
                        'Atenção'
                    )
                }
                closable={true}
                open={paywall.open}
                onClose={() =>
                    setPaywall({
                        open: false,
                        reason: ''
                    })
                }
                onCancel={() =>
                    setPaywall({
                        open: false,
                        reason: ''
                    })
                }
                footer={
                    paywall.reason === 'PAYMENT'
                        ? [
                            <Button
                                key="painelPaywall"
                                type="subtle"
                                onClick={() => {
                                    setPaywall({
                                        open: false,
                                        reason: ''
                                    })
                                    navigate('/')
                                }}
                            >
                                Voltar para o painel
                            </Button>,
                            <Button
                                key="financeiroPaywall"
                                style={{ color: 'white' }}
                                type="primary"
                                onClick={() => {
                                    setPaywall({
                                        open: false,
                                        reason: ''
                                    })
                                    navigate('/configuracoes/financeiro')
                                }}
                            >
                                Regularizar pendência
                            </Button>
                        ]
                        : [
                            <Button
                                key="painelPaywall"
                                type="primary"
                                style={{ color: 'white' }}
                                onClick={() => {
                                    setPaywall({
                                        open: false,
                                        reason: ''
                                    })
                                    navigate('/configuracoes/financeiro')
                                }}
                            >
                                Configurar meu plano
                            </Button>,
                            <Button
                                key="financeiroPaywall"
                                style={{ color: 'white' }}
                                type="secondary"
                                onClick={() => {
                                    setPaywall({
                                        open: false,
                                        reason: ''
                                    })
                                    window.open(moduleLink[paywall.reason], '_blank')
                                }}
                            >
                                Conheça {moduleMap[paywall.reason]}
                            </Button>
                        ]
                }
            >
                {paywall.reason === 'PAYMENT' && (
                    <Col style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <img
                            src={
                                paywall.reason !== 'PAYMENT'
                                    ? PaywallLogo2
                                    : PaywallLogo1
                            }
                        />
                        <Text
                            emphasis
                            style={{ marginBottom: '20px', marginTop: '20px' }}
                            size="h6"
                        >
                            Seu plano atual está suspenso por problemas financeiros.
                        </Text>
                        <Text style={{ marginBottom: '30px' }} emphasis>
                            Para que você possa utilizar nosso sistema normalmente,
                            precisamos que regularize esta pendência.
                        </Text>
                        <Text style={{ marginBottom: '10px' }}>
                            Isso pode ter acontecido porque:
                        </Text>
                        <Text color={theme.color.text.subtlest}>
                            1. Há algum problema com seu banco;
                        </Text>
                        <Text color={theme.color.text.subtlest}>
                            2. O cartão informado para pagamento está vencido;
                        </Text>
                        <Text color={theme.color.text.subtlest}>
                            3. Não há saldo suficiente em sua conta;
                        </Text>
                    </Col>
                )}
                {paywall.reason !== 'PAYMENT' && (
                    <Col style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <img
                            src={
                                paywall.reason !== 'PAYMENT'
                                    ? PaywallLogo2
                                    : PaywallLogo1
                            }
                        />
                        <Text
                            emphasis
                            style={{ marginBottom: '20px', marginTop: '20px' }}
                            size="h6"
                        >
                            Você não possui o módulo do {moduleMap[paywall.reason]}
                        </Text>
                        <Text style={{ marginBottom: '30px' }} emphasis>
                            {paywallText[paywall.reason]}
                        </Text>
                    </Col>
                )}
            </Modal>
            {barContent}
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;