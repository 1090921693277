import React, { useContext, useEffect, useRef, useState } from 'react'
import '../styles.css'
import {
  Layout,
  Menu,
  Button,
  Text,
  PrevLogo,
  Row,
  Col,
  Searchbar,
  Avatar,
  Dropdown,
  usePrevTheme,
  Badge,
  Divider,
  Card,
  Space,
  Typography,
  Slider,
  Tooltip
} from '@prev/ui-kit'
import styled from 'styled-components'
import IAIcon from "../assets/IA.svg"

import {
  BankOutlined,
  SolutionOutlined,
  PlusCircleOutlined,
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  WalletOutlined,
  MenuFoldOutlined,
  BookOutlined,
  QuestionCircleOutlined,
  AppstoreOutlined,
  FileSearchOutlined,
  BellOutlined,
  AuditOutlined,
  LaptopOutlined,
  SettingOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons'
import { getInitials, getSlugFromUrl } from '../utils'
import { SearchContext } from '../contexts/SearchContext'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { UserContext } from '../contexts/UserContext'
import useWidth from '../hooks/useWidth'
import DrawerSelecionarCalculo from '../components/CalculoOpcoes'
import { OnboardingModal } from '../components/Onboarding'

interface siderItems {
  icon: any
  displayName: string
  link?: string
  options?: any[]
  key?: string
}

const StyledTooltip = styled(Tooltip)``

const StyledSlider = styled(Slider)`
   .ant-slider-handle {
      display: none !important;
  }

  .ant-slider-track {
    background-color: ${(props) => props.theme.color.icon.brand}; /* Cor da linha preenchida */
    height: 4px; /* Altura da linha */
    border-radius: 4px;
}
`

interface ILayout {
  children: any
  siderWidth?: number
  siderItems?: siderItems[]
  onSearch?: (inputRef: any) => void
  onNewCalculation?: () => void
  profileBadgeCount?: number
  navigate?: any
}

const { Header, Content, Sider } = Layout
const StyledSider = styled(Sider)`
  && {
    padding: 0;
    border: none;
    min-height: 100vh;
    background-color: ${(props) => props.theme.color.surface.container.default};
  }
  .ant-menu-item-group-title {
    padding-left: 0;
  }
`
const StyledMenu = styled(Menu)`
  && .ant-menu-submenu-selected > .ant-menu-submenu-title,
  && .ant-menu-item-selected {
    border-radius: 2px 4px 4px 2px;
    height: 32px;
    color: ${(props) => props.theme.color.text.brand} !important;
    background-color: ${(props) => props.theme.color.background.disabled};
  }

  && .ant-menu-item-active:hover,
  && .ant-menu-submenu-title:hover,
  && .ant-menu-item:not(.ant-menu-item-selected):hover,
  &&
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ) {
    border-radius: 2px 4px 4px 2px;
    height: 32px; 
  }

  && .ant-menu-sub.ant-menu-inline {
    background: none;
  }

  .ant-menu-item {
    height: 32px; 
    padding-left: 10px; 
    margin-bottom: 4px;
    border-radius: 4px;
  }

  .ant-menu-submenu-title {
    padding-left: 10px; 
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title,
  .ant-menu-item-selected {
    border-left: 2px solid ${(props) => props.theme.color.text.brand};
  }
`;

interface StyledContentProps {
  collapsed?: boolean;
}

const StyledContent = styled(Content) <StyledContentProps>`
  padding: 16px 12px 16px 32px;
  height: ${((props) => {
    const barcontent = props.collapsed
    return `calc(100vh - ${barcontent ? '140px' : '64px'})`
  })};
`

export const PrevLayout = ({ children, siderWidth = 265 }: ILayout): JSX.Element => {
  const [selected, setSelected] = useState<string[]>(['/'])
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 1200)
  const { userInfo, barContent, checkPaywall } = useContext(UserContext)
  const { setActualPath, setQuery, query, setSearchQuery, actualPath, handleFindBy } = useContext(SearchContext)
  const containerRef = useRef<HTMLDivElement>(null) // Adicionar referência ao contêiner
  const [checkedSlug, setCheckedSlug] = useState(false)
  const [openCalculos, setOpenCalculos] = useState(false)
  const [showOnboarding, setShowOnboarding] = useState(false)
  const [onboardingUser, setOnboardingUser] = useState<any>()
  const navigateDom = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const { darkMode, theme } = usePrevTheme()
  const width = useWidth()
  const { consumoModulos } = useContext(UserContext)
  const moduloCAsos = consumoModulos?.modulos.find(e => e.tipo.includes('CASOS'))

  const checkSlug = () => {
    const hasSlug = getSlugFromUrl()
    if (hasSlug || (userInfo && !userInfo.escritorio)) {
      setCheckedSlug(true)
    }
    if (!hasSlug && userInfo && userInfo.escritorio?.slug) {
      navigateDom('/login')
    }
  }

  const checkOnboading = async (): Promise<void> => {
    try {
      if (userInfo && !userInfo?.escritorio) {
        setOnboardingUser(userInfo)
        setShowOnboarding(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    void checkOnboading()
  }, [userInfo])

  useEffect(() => {
    checkSlug()
  }, [userInfo])

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.backgroundColor =
      theme.color.surface.container.default
  }, [darkMode])

  const menuItemStyle = (key) => {
    return (
      {
        color: selected.includes(key) ? theme.color.text.brand : 'inherit',
        display: 'flex',
        alignItems: 'center',
        fontWeight: selected.includes(key) ? 600 : 400
      }
    )
  };

  const iconStyle = (key) => ({
    color: selected.includes(key) ? theme.color.text.brand : theme.color.icon.subtle,
    fontSize: "18px",
    marginRight: "4px"
  });

  const showPrevCasosUpdate = () => {
    if (!moduloCAsos) return false
    if (moduloCAsos.tipo.includes("ILIMITADO")) return
    const quantidadeMinima = moduloCAsos.quantidadeMinimaUsos
    const quantidadeMaxima = moduloCAsos.quantidadeMaximoUsos
    if ((quantidadeMaxima - quantidadeMinima) <= 3) return true
    return false
  }

  useEffect(() => {
    const currentUrl = pathname
    if (
      [
        '/peticoes',
        '/jurisprudencia',
        '/clientes',
        '/laudos',
        '/casos',
        '/cursos',
        '/'
      ].includes(currentUrl)
    ) {
      setSelected([currentUrl])
      setActualPath(['/peticoes', '/jurisprudencia', '/clientes', '/laudos'].find(e => e === currentUrl) ? currentUrl : '/')
    }
  }, [pathname])

  const handleSelect = ({ key }) => {
    setSelected([key]);
  };

  return (
    <Layout
      className="layoutPrev"
      style={{ position: 'fixed', width: '100%', zIndex: 50 }}
    >
      <OnboardingModal
        isOpen={showOnboarding}
        onClose={() => {
          setShowOnboarding(false)
        }}
        onboardingUser={onboardingUser}
      />
      <Header
        style={{
          height: '64px',
          backgroundColor: theme.color.surface.container.default,
          borderBottom: `1px solid ${theme.color.border.default}`,
          padding: '0 16px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Row align="middle" justify='center'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PrevLogo />
          </div>
        </Row>
        <DrawerSelecionarCalculo open={openCalculos} setOpen={setOpenCalculos} />
        <div style={{ maxWidth: "1366px", width: "100%", margin: "auto", position: "relative", paddingLeft: width > 1080 ? "80px" : "40px", }}>
          <Row align="middle" justify="space-between" wrap={false} style={{ gap: width < 1600 ? "0" : "40px" }}>
            <Col span={width < 1080 ? 15 : 16}>
              <Row wrap={false}>
                <Col span={22}>
                  <Searchbar
                    data-testid="inpt-header-searchbar"
                    actualPath={actualPath as '/'}
                    clearSearch={() => {
                      setQuery('')
                      setSearchQuery("")
                    }}
                    width='100%'
                    setSearchQuery={setSearchQuery}
                    navigate={navigateDom}
                    handleChange={setQuery}
                    value={query}
                    searchQuery={query}
                    setActualPath={setActualPath}
                    showModal={() => true}
                    showHistory={true}
                    findBy={handleFindBy}
                  />
                </Col>
                <Col>
                  <Card style={{
                    borderRadius: "4px", marginLeft: '8px', border: "none", boxShadow: "0px 1px 2px 1px rgba(39, 39, 46, 0.25), 0px 0px 1px 0px rgba(39, 39, 46, 0.31)",
                  }} styles={{ body: { padding: "8px", width: "50px", height: "40px", } }} data-testid='card-buca-ai' aria-label=''>
                    <Row>
                      <img src={IAIcon} style={{ width: "20px", height: "20px" }} />
                      <Text>AI</Text>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={width < 1080 ? 9 : 8} style={{ width: '100%' }}>
              <Row style={{ width: '100%', gap: '24px' }} justify="end" align='middle' wrap={false}>
                <Col style={{ display: "none" }}>
                  <Badge style={{ color: '#FDFDFF', backgroundColor: theme.color.background.danger.bold.default, fontSize: "12px", }} data-testid='count-notificacoes' count={25}>
                    <BellOutlined style={{ fontSize: '16px' }} />
                  </Badge>
                </Col>
                <Col>
                  <Dropdown
                    data-testid='dropdown-configuracoes-header'
                    overlayStyle={{ cursor: "pointer" }}
                    overlay={() => (
                      <Menu
                        data-testid='menu-configuracoes-header'
                        style={{ backgroundColor: theme.color.surface.default }}
                      >
                        <Menu.Item key="1" data-testid="btn-header-menu-conta" style={{ marginBottom: "8px", height: "40px" }}>
                          <Link to="/configuracoes/conta">
                            <Row
                              style={{
                                gap: '8px',
                                color: theme.color.text.default
                              }}
                            >
                              <UserOutlined />
                              <Text>Minha conta</Text>
                            </Row>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="2" data-testid="btn-header-menu-financeiro" style={{ marginBottom: "8px", height: "40px" }}>
                          <Link to="/configuracoes/financeiro">
                            <Row
                              style={{
                                gap: '8px',
                                color: theme.color.text.default
                              }}
                            >
                              <WalletOutlined />
                              <Text>Meu financeiro</Text>
                            </Row>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="3"
                          data-testid="btn-header-menu-configuracoes"
                          style={{ marginBottom: "8px", height: "40px" }}
                        >
                          <Link to="/configuracoes">
                            <Row
                              style={{
                                gap: '8px',
                                color: theme.color.text.default
                              }}
                            >
                              <SettingOutlined />
                              <Text>Configurações do escritório</Text>
                            </Row>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="4"
                          data-testid="btn-header-menu-configuracoes"
                          style={{ marginBottom: "8px", height: "40px" }}
                        >
                          <Link to="https://ajuda.previdenciarista.com/" target='_blank'>
                            <Row
                              style={{
                                gap: '8px',
                                color: theme.color.text.default
                              }}
                            >
                              <QuestionCircleOutlined />
                              <Text>Ajuda</Text>
                            </Row>
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          style={{ marginBottom: "8px", height: "40px" }}
                          data-testid="btn-header-menu-sair"
                          key="5"
                          onClick={() => {
                            localStorage.removeItem('access_token_prev')
                            navigateDom('/login')
                          }}
                        >
                          <Row
                            style={{ gap: '8px', color: theme.color.text.default }}
                          >
                            <LogoutOutlined />
                            <Text>Sair do sistema</Text>
                          </Row>
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Row align='middle' wrap={false} style={{ cursor: "pointer" }}>
                      <Col>
                        <Avatar
                          data-testid="tag-casos-1"
                          aria-label="avatar-l"
                          src={userInfo?.usuario.urlImagemPerfil}
                          style={{
                            backgroundColor:
                              theme.color.background.accent.brand.subtlest.default,
                            color: theme.color.text.brand,
                            width: '32px',
                          }}
                        >
                          {getInitials(userInfo?.usuario.nome ?? '')}
                        </Avatar>
                      </Col>
                      <Col
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginLeft: '8px',
                        }}
                      >
                        <Row wrap={false} style={{ gap: "5px" }}>
                          <Typography style={{ color: theme.color.text.default }}>
                            {userInfo?.usuario.nome}
                          </Typography>
                          <DownOutlined />
                        </Row>
                        <Typography
                          style={{ color: theme.color.text.subtlest, fontSize: '12px' }}
                        >
                          {userInfo?.escritorio?.nome}
                        </Typography>
                      </Col>
                    </Row>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Header>
      <Layout>
        <StyledSider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={siderWidth}
          style={{
            borderRight: `1px solid ${theme.color.border.default}`,
            backgroundColor: theme.color.surface.container.default
          }}
        >
          <div
            style={{
              display: 'flex',
              height: 'max-content',
              justifyContent: 'center',
              marginTop: '15px'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '17px',
                zIndex: 50,
                right: collapsed ? "-25px" : '-20px',
                backgroundColor: theme.color.surface.container.default,
                padding: '8px',
                boxShadow:
                  '0px 8px 12px 0px rgba(39, 39, 46, 0.15), 0px 0px 1px 0px rgba(39, 39, 46, 0.30)',
                borderRadius: '4px',
                border: `1px solid ${theme.color.border.default}`,
                cursor: 'pointer'
              }}
              onClick={() => {
                setCollapsed(!collapsed)
              }}
            >
              {
                collapsed ? <MenuUnfoldOutlined
                  style={{ color: theme.color.text.default, fontSize: '16px' }}
                /> :
                  <MenuFoldOutlined
                    style={{ color: theme.color.text.default, fontSize: '16px' }}
                  />
              }
            </div>
            <Button
              type="primary"
              style={{
                height: '48px',
                display: 'flex',
                margin: "0 26px",
                width: "210px",
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: 'none'
              }}
              onClick={() => {
                const ok = checkPaywall()
                if (ok) {
                  setOpenCalculos(!openCalculos)
                }
              }}
            >
              <PlusCircleOutlined style={{ color: '#FDFDFF', fontSize: '20px', fontWeight: 400 }} />
              {!collapsed && (
                <Text color="#FDFDFF" style={{ fontWeight: 400, fontSize: '20px' }}>
                  Novo cálculo
                </Text>
              )}
            </Button>
          </div>
          <StyledMenu
            style={{
              marginTop: 10,
              padding: "0 16px",
              border: 'none',
              backgroundColor: theme.color.surface.container.default
            }}
            onSelect={handleSelect}
            selectedKeys={selected}
            data-testid='menu-layout'
            mode="inline"

            defaultSelectedKeys={['1']}
          >
            <Menu.Item title={null} onClick={() => {
              setSearchQuery("")
              setQuery("")
              navigateDom('/')
            }} style={{ paddingLeft: '12px', display: "flex" }} key="/"
              icon={
                collapsed ? <StyledTooltip
                  arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: theme.color.surface.default,
                    color: theme.color.text.subtle,
                    fontSize: "12px",
                    padding: '8px',
                    lineHeight: "150%"
                  }}
                  data-testid='tooltip-menu-dashboard' placement='right' title='Dashboard'>
                  <AppstoreOutlined style={iconStyle('/')} />
                </StyledTooltip> :
                  <AppstoreOutlined style={iconStyle('/')} />
              }
            >
              <Text style={menuItemStyle('/')}>Dashboard</Text>
            </Menu.Item>
            <Menu.Item title={null} onClick={() => {
              setSearchQuery("")
              setQuery("")
              navigateDom('/clientes')
            }} style={{ paddingLeft: '12px', display: "flex" }} key="/clientes"
              icon={
                collapsed ? <StyledTooltip arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: theme.color.surface.default,
                    color: theme.color.text.subtle,
                    fontSize: "12px",
                    padding: '8px',
                    lineHeight: "150%"
                  }} data-testid='tooltip-menu-clientes' placement='right' title='Clientes'>
                  <UserOutlined style={iconStyle('/clientes')} />
                </StyledTooltip> :
                  <UserOutlined style={iconStyle('/clientes')} />
              }
            >
              <Text style={menuItemStyle('/clientes')}>Clientes</Text>
            </Menu.Item>
            <Menu.ItemGroup title={<Text style={{ fontSize: "12px", color: "#7C7B84" }}>Buscas</Text>}>
              <Menu.Item title={null} onClick={() => {
                setSearchQuery("")
                setQuery("")
                navigateDom('/peticoes')
              }} style={{ paddingLeft: '12px', display: "flex" }} key="/peticoes"
                icon={
                  collapsed ? <StyledTooltip arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }} data-testid='tooltip-menu-peticoes' placement='right' title='Petições'>
                    <FileSearchOutlined style={iconStyle('/peticoes')} />
                  </StyledTooltip> :
                    <FileSearchOutlined style={iconStyle('/peticoes')} />
                }
              >
                <Text style={menuItemStyle('/peticoes')}>Busca de Petições</Text>
              </Menu.Item>
              <Menu.Item title={null} onClick={() => {
                setSearchQuery("")
                setQuery("")
                navigateDom('/laudos')
              }} style={{ paddingLeft: '12px', display: "flex" }} key="/laudos"
                icon={
                  collapsed ? <StyledTooltip arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }} placement='right' title='Laudos' data-testid='tooltip-menu-laudos'>
                    <AuditOutlined style={iconStyle('/laudos')} />
                  </StyledTooltip> :
                    <AuditOutlined style={iconStyle('/laudos')} />
                }
              >
                <Text style={menuItemStyle('/laudos')}>Busca de Laudos</Text>
              </Menu.Item>
              <Menu.Item title={null} onClick={() => {
                setSearchQuery("")
                setQuery("")
                navigateDom('/jurisprudencia')
              }} style={{ paddingLeft: '12px', display: "flex" }} key="/jurisprudencia"
                icon={
                  collapsed ? <StyledTooltip arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }} data-testid='tooltip-menu-jurisprudencia' placement='right' title='Jurisprudências'>
                    <BankOutlined style={iconStyle('/jurisprudencia')} />
                  </StyledTooltip> :
                    <BankOutlined style={iconStyle('/jurisprudencia')} />
                }
              >
                <Text style={menuItemStyle('/jurisprudencia')}>Busca de Jurisprudências</Text>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title={<Text style={{ fontSize: "12px", color: "#7C7B84" }}>Módulos</Text>}>
              <Menu.Item title={null} onClick={() => {
                setSearchQuery("")
                setQuery("")
                navigateDom('/casos')
              }} style={{ paddingLeft: '12px', display: "flex" }} key="/casos"
                icon={
                  collapsed ? <StyledTooltip arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }} data-testid='tooltip-menu-casos' placement='right' title='Casos'>
                    <SolutionOutlined style={iconStyle('/casos')} />
                  </StyledTooltip> :
                    <SolutionOutlined style={iconStyle('/casos')} />
                }
              >
                <Text style={menuItemStyle('/casos')}>PrevCasos</Text>
              </Menu.Item>
              <Menu.Item title={null} onClick={() => window.open('/meu-escritorio', '_blank')} style={{ paddingLeft: '12px', display: "flex" }} key="/diretorio"
                icon={
                  collapsed ? <StyledTooltip arrow={false}
                    overlayInnerStyle={{
                      backgroundColor: theme.color.surface.default,
                      color: theme.color.text.subtle,
                      fontSize: "12px",
                      padding: '8px',
                      lineHeight: "150%"
                    }} data-testid='tooltip-menu-diretorio' placement='right' title='Meu diretório'>
                    <LaptopOutlined style={iconStyle('/diretorio')} />
                  </StyledTooltip> :
                    <LaptopOutlined style={iconStyle('/diretorio')} />
                }
              >
                <Text style={menuItemStyle('/diretorio')}>Meu Diretório</Text>
              </Menu.Item>
            </Menu.ItemGroup>
            <Divider style={{ padding: 0, margin: "8px 0" }} />
            <Menu.Item title={null} onClick={() => {
              setSearchQuery("")
              setQuery("")
              navigateDom('/cursos')
            }} style={{ paddingLeft: '12px', display: "flex" }} key="/cursos"
              icon={
                collapsed ? <StyledTooltip arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: theme.color.surface.default,
                    color: theme.color.text.subtle,
                    fontSize: "12px",
                    padding: '8px',
                    lineHeight: "150%"
                  }} data-testid='tooltip-menu-cursos' placement='right' title='Cursos'>
                  <BookOutlined style={iconStyle('/cursos')} />
                </StyledTooltip> :
                  <BookOutlined style={iconStyle('/cursos')} />
              }
            >
              <Text style={menuItemStyle('/cursos')}>Cursos</Text>
            </Menu.Item>
            {
              !collapsed && showPrevCasosUpdate() && moduloCAsos && (
                <Card data-testid='card-consumo-casos' aria-label='' style={{ marginTop: "28px" }} styles={{ body: { paddingBottom: 0 } }}>
                  <Text emphasis style={{ fontSize: "14px", marginBottom: '8px' }}>Faça já um upgrade!</Text>
                  <Text style={{ fontSize: "12px", color: theme.color.text.subtle, marginBottom: "8px" }}>
                    Faltam apenas {moduloCAsos?.quantidadeMaximoUsos - moduloCAsos?.quantidadeUtilizada}  casos para atingir o limite máximo do seu plano atual.
                  </Text>
                  <StyledSlider data-testid='' value={moduloCAsos?.quantidadeUtilizada} max={moduloCAsos?.quantidadeMaximoUsos} />
                </Card>
              )
            }
          </StyledMenu>
        </StyledSider>
        <StyledContent
          id="prev-container"
          collapsed={barContent}
          ref={containerRef}
          style={{
            overflowY: 'auto',
            backgroundColor: theme.color.surface.default
          }}
        >
          {checkedSlug && children}
        </StyledContent>
      </Layout>
    </Layout>
  )
}
