import React, { createContext, useState } from 'react'
import { useSpring, animated, type SpringValue } from 'react-spring'
import { Row, usePrevTheme } from '@prev/ui-kit'
import { UseSearchInterface } from '@prev-front/mf-template'
import { CalcView } from '../components/CalcView'

interface Endereco {
  cep: string
  endereco: string
  cidade: string
  complemento: string
  uf: string
  bairro: string
  numero: string
}

export interface UserProfile {
  profissao?: string
  nome?: string
  cpf?: string
  dataDeNascimento?: string
  sexo?: string
  email?: string
  telefone?: string
  celular?: string
  endereco?: Endereco
  numeroDaOAB?: string
}

export interface AnimationHandlerInterface {
    calcView: {
      isVisible: boolean
      calcViewX: SpringValue<number>
      setShowCalcTab: React.Dispatch<React.SetStateAction<boolean>>
    }
}

export interface CasosViewInterface {
  isVisible: boolean
  id: string | undefined
  title: undefined | string
}

export const FormAnimationContext = createContext<AnimationHandlerInterface>(
  {} as any
)

interface FormAnimationProviderProps {
  children: React.ReactNode
  searchContext: UseSearchInterface  & { scrollTop: () => void, checkPaywall: (module?:string) => boolean }
}

export const FormAnimationProvider = ({ children, searchContext }: FormAnimationProviderProps): JSX.Element => {
  const [showCalcTab, setShowCalcTab] = useState(false)

  const resizeObserver = new ResizeObserver(() => {
    window.outerWidth = window.innerWidth
  })

  resizeObserver.observe(document.documentElement)

  const { theme } = usePrevTheme()

  const { x: calcViewX } = useSpring({
    x: showCalcTab ? 0 : -window.outerWidth
  })


  const contextValue = {
    calcView: {
      isVisible: showCalcTab,
      calcViewX,
      setShowCalcTab
    },
  }

  return (
    <FormAnimationContext.Provider value={contextValue}>
      {children}
      <animated.div
        className="content"
        style={{
          transform: calcViewX.to((x) => `translate3d(${x * -1}px, 0, 0)`),
          position: 'fixed',
          display: showCalcTab ? 'block' : 'none',
          top: 0,
          left: 0,
          zIndex: 50,
          width: '100vw',
          height: '100vh',
          padding: '20px',
          backgroundColor: theme.color.surface.container.default,
          overflowY: 'scroll'
        }}
      >
        <Row
          style={{
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'space-between',
            zIndex: 999999999999999
          }}
        >
          <CalcView  />
        </Row>
      </animated.div>
    </FormAnimationContext.Provider>
  )
}
