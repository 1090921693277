import { authGet, authPost, authPut, get, post } from "@prev-front/shared-api"
import qs from 'qs'
import { API_AUTH, API_HOST_DASHBOARD_V2 } from "./constants"
import { ObjetoUserProfile, PrevCasosConsumoInterface } from "../contexts/UserContextTypes"

interface LoginData {
  grant_type: string
  slug: string
  username: string
  password: string
}

export interface IToken {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: string
}


export const login = async (data: LoginData): Promise<IToken> => {
  const authApp = `Basic ${API_AUTH}`
  const payload = {
    grant_type: data.grant_type || 'password',
    slug: data.slug,
    username: data.username,
    password: data.password
  }

  const response = await post('/oauth/token', qs.stringify(payload), {
    headers: {
      Authorization: authApp,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  return response as IToken
}

export const getPrevCasosConsumo = async (): Promise<PrevCasosConsumoInterface> => {
  const response = await authGet('/info/consumo', API_HOST_DASHBOARD_V2)
  return response as PrevCasosConsumoInterface
}

export const changeAccountInfo = async (payload: ObjetoUserProfile): Promise<void> => {
  await authPut({
    data: payload,
    path: '/contas/info'
  })
}

export const getSlug = async (slug: string): Promise<any> => {
  const data = await get(`/oauth/escritorios/${slug}`)
  return data
}

export const updateOnboarding = async (values): Promise<void> => {
  await authPost({
    path: '/escritorios',
    data: values
  })
}